import { useState } from 'react'
import { Alert, Modal, ModalBody, Container } from 'reactstrap';
import logo from '../assets/logo.png'
import LoadingButton from './LoadingButton';

interface AskReasonModalnterface {
    alert: string | null,
    dismissAlert: () => void,
    networkShare: boolean,
    modal: boolean,
    unsubscribing: boolean,
    productId: string | undefined,
    toggle: () => void,
    onConfirmUnsubscribe: (productId: string | undefined, reason: Array<{ reason: string; answer?: string }>) => void
}

function AskReasonModal(props: AskReasonModalnterface) {
    const { alert, dismissAlert, modal, unsubscribing, toggle, onConfirmUnsubscribe, networkShare, productId } = props

    const deleteConfirmationFeedbackItems = [
        { reason: 'Privacy Concerns', followUpQuestion: null },
        { reason: 'Technical Issues', followUpQuestion: 'Kindly Explain Your Technical Issue(s):' },
        { reason: 'Poor User Experience', followUpQuestion: 'Kindly Explain: 🙂' },
        { reason: 'High Subscription Costs', followUpQuestion: null },
        { reason: 'Limited Content or Features', followUpQuestion: "What you'd like to see added?" },
        { reason: 'Switching To Another Service', followUpQuestion: "Kindly explain the reason for the switch:" },
        { reason: 'Other Reason', followUpQuestion: 'Kindly Explain: 😊' },
    ];
    const [deleteConfirmationItemsSelected, setDeleteConfirmationItemsSelected] = useState<Array<{ reason: string; answer?: string; }>>([]);

    const handleDeleteConfirmationItemSelect = (item: string) => {
        if (deleteConfirmationItemsSelected.some(obj => obj.reason === item)) {
            console.log(deleteConfirmationItemsSelected.filter(i => i.reason !== item))
            setDeleteConfirmationItemsSelected(
                deleteConfirmationItemsSelected.filter(i => i.reason !== item),
            );
        } else {
            setDeleteConfirmationItemsSelected([
                ...deleteConfirmationItemsSelected,
                { reason: item },
            ]);
        }
    };

    const addAnswer = (reason_: string, answer: string) => {
        const newdeleteConfirmationItemsSelected = deleteConfirmationItemsSelected.map(values => {
            const { reason } = values
            if (reason === reason_) {
                return { reason, answer }
            }
            return values
        })
        setDeleteConfirmationItemsSelected(newdeleteConfirmationItemsSelected)
    }
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalBody>
                <Container>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={logo} alt="React Logo" style={{ width: 50, height: 50 }} />
                    </div>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 style={{ textAlign: 'center' }}>
                            Please select from the following the reason why you wanted
                            to unsubscribe{networkShare && " to our network share"}.
                        </h5>
                    </div>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 style={{ textAlign: 'center' }}>
                            You can choose two or more reasons.
                        </h5>
                    </div>
                    <Container>
                        {
                            deleteConfirmationFeedbackItems.map((data, key) => {
                                const { reason, followUpQuestion } = data
                                return (
                                    <Container key={key}>
                                        <div style={{ display: 'flex', gap: 10, justifyContent: 'start', margin: 10 }}>
                                            <input type="checkbox" onChange={() => {
                                                handleDeleteConfirmationItemSelect(reason);
                                            }} />
                                            <div style={{ fontSize: '14px' }}>
                                                {reason}
                                            </div>
                                        </div>
                                        {
                                            (deleteConfirmationItemsSelected.some(obj => obj.reason === reason) && followUpQuestion) &&
                                            <textarea onChange={(e) => addAnswer(reason, e.target.value)} style={{ width: '100%', height: '100px' }} placeholder={followUpQuestion} />
                                        }
                                    </Container>
                                )
                            })
                        }
                    </Container>
                    { !!alert && <Alert className="mt-2" toggle={dismissAlert} color='danger'>{alert}</Alert>}
                    <div style={{ display: 'flex', margin: 10, justifyContent: 'space-around' }}>
                        <LoadingButton onClick={toggle} style={{ backgroundColor: '#3A5579' }}> Cancel</LoadingButton>
                        <LoadingButton loading={unsubscribing} disabled={deleteConfirmationItemsSelected.length < 2 || unsubscribing} onClick={() => {
                            onConfirmUnsubscribe(productId, deleteConfirmationItemsSelected)
                        }} style={{ backgroundColor: 'red' }}> Confirm</LoadingButton>
                    </div>
                </Container>

            </ModalBody>
        </Modal>
    )
}

export default AskReasonModal