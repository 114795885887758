export const enum Endpoints {
    USER_LOGIN = 'userLogin',
    USER_TOKEN_LOGIN = 'userTokenLogin',
    USER_REGISTER = 'userRegister',
    SEND_SMS = 'sendSMSCode',
    SUBMIT_VERIFY_CODE = 'submitEmailVerifyCode',
    CREATE_SUBSCRIPTION = 'merge/createSubscription',
    UPDATE_SUBSCRIPTION = 'merge/updateSubscription',
    ATTACH_NEW_PAYMENT_METHOD = 'merge/attachNewPaymentMethod',
    UNSUBSCRIBE = 'merge/unsubscribe',
    UNSUBSCRIBE_NETWORK_SHARE = 'unsubscribeNetworkShare',
    GET_SUBSCRIPTION = "api/subscription/",
    GET_STRIPE_SUBSCRIPTION = 'merge/getStripeSubscription',
    GET_NETWORK_SHARE_SUBSCRIPTION = 'getNetworkShareSubscription',
    GET_COUPON_DETAILS = 'getCouponDetails',
    GET_POSHMARK_ACCOUNTS = 'getPoshmarkAccounts',
    URL_APPLY_COUPON = 'applyCoupon'
}