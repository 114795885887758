export const enum PLAN_MODE {
    PSK_CL = 'Sidekick',
    CL = 'Crosslisting Only'
}

export const enum PLAN_TITLES {
    PSK = "Posh Sidekick",
    SK_BASIC = "Sidekick Basic",
    SK_SILVER = "Sidekick Silver",
    SK_GOLD = "Sidekick Gold",
    SK_PRO = "Sidekick Pro",
    SK_ENTERPRISE = "Sidekick Enterprise",
    CL_BASIC = "Crosslisting Basic",
    CL_SILVER = "Crosslisting Silver",
    CL_GOLD = "Crosslisting Gold",
    CL_PRO = "Crosslisting Pro",
    CL_ENTERPRISE = "Crosslisting Enterprise"
}

export const PLAN_FEATURES = {
    [PLAN_TITLES.PSK]: {
        "Posh Sidekick Features": "Yes",
        "Crosslists": "25 Crosslists",
        "Promoted Listings": "No",
        "Maintenance of Crosslisted Items": "No",
        "Bulk Image Background Remover": "No"
    },
    [PLAN_TITLES.SK_BASIC]: {
        "Posh Sidekick Features": "Yes",
        "Crosslists": "50 Crosslists",
        "Promoted Listings": "1000 Closets per day 7 items",
        "Maintenance of Crosslisted Items": "Yes",
        "Bulk Image Background Remover": "No",
    },
    [PLAN_TITLES.SK_SILVER]: {
        "Posh Sidekick Features": "Yes",
        "Crosslists": "150 Crosslists",
        "Promoted Listings": "1500 Closets per day 7 + 1 items",
        "Maintenance of Crosslisted Items": "Yes",
        "Bulk Image Background Remover": "No",
    },
    [PLAN_TITLES.SK_GOLD]: {
        "Posh Sidekick Features": "Yes",
        "Crosslists": "250 Crosslists",
        "Promoted Listings": "2000 Closets per day 8 + 1 items",
        "Maintenance of Crosslisted Items": "Yes",
        "Bulk Image Background Remover": "No",
    },
    [PLAN_TITLES.SK_PRO]: {
        "Posh Sidekick Features": "Yes",
        "Crosslists": "500 Crosslists",
        "Promoted Listings": "2000 Closets per day 9 + 1 items",
        "Maintenance of Crosslisted Items": "Yes",
        "Bulk Image Background Remover": "1000 Images per month",
    },
    [PLAN_TITLES.SK_ENTERPRISE]: {
        "Posh Sidekick Features": "Yes",
        "Crosslists": "1000 Crosslists",
        "Promoted Listings": "2000 Closets per day 10 items",
        "Maintenance of Crosslisted Items": "Yes",
        "Bulk Image Background Remover": "Unlimited",
    },
    [PLAN_TITLES.CL_BASIC]: {
        "Crosslists": "100 Crosslists",
        "Maintenance of Crosslisted Items": "Yes",
        "Relisting": [10]
    },
    [PLAN_TITLES.CL_SILVER]: {
        "Crosslists": "250 Crosslists",
        "Maintenance of Crosslisted Items": "Yes",
        "Relisting": [10, 15]
    },
    [PLAN_TITLES.CL_GOLD]: {
        "Crosslists": "500 Crosslists",
        "Maintenance of Crosslisted Items": "Yes",
        "Relisting": [10, 15, 20]
    },
    [PLAN_TITLES.CL_PRO]: {
        "Crosslists": "750 Crosslists",
        "Maintenance of Crosslisted Items": "Yes",
        "Relisting": [10, 15, 20]
    },
    [PLAN_TITLES.CL_ENTERPRISE]: {
        "Crosslists": "1000 Crosslists",
        "Maintenance of Crosslisted Items": "Yes",
        "Relisting": [10, 15, 20]
    }
}

export const PSK_MAIN_FEATURES = [
    'Self Shares', 
    'Party Shares', 
    'Return Shares', 
    'Community Shares', 
    'Safety pause', 
    'Unfollow', 
    'Follow Backs', 
    'Follow Closets', 
    'Randomization', 
    'Closet Snapshot', 
    'Background Remover', 
    'Relisting of Older Items', 
    'Automated Silent Auctions', 
    'Activity Monitoring and Insights', 
    'Custom Categories for Sharing, Live Auction and Offers'
]

export const PSK_OTHER_FEATURES = [
    'Sales Report', 
    'Reserve Pricing',
    'No Captcha Hassle', 
    'Share Speed Option'
];

export const CL_RELISTING_FEATURES = [
    "Relisting on Mercari",
    "Relisting on Depop",
    "Relisting on eBay",
    "Relisting on Kidizen",
    "Relisting on Grailed",
    "Up to X relists per day / platform"
]