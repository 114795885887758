import React, { useState, useImperativeHandle } from 'react'
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import emailIcon from '../../assets/email.png';

const WithCode = React.forwardRef((props, ref) => {
    const [email, setEmail] = useState("");

    useImperativeHandle(ref, () => ({
        getLoginInfo: () => ({ username: email })
    }))

    return (
        <>
            <InputGroup className='mt-2'>
                <InputGroupText>
                    <img alt='...' src={emailIcon} width={25} height={25} />
                </InputGroupText>
                <Input placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
            </InputGroup>
        </>
    )
})

export default WithCode;